import React from "react";

import ThankyouImg from "../../../../assets/images/banner/thankyou.gif";

const index = () => {
  return (
    <div>
      <div className="ThankyouImg">
        <img src={ThankyouImg} />
      </div>
      <div className="PlayBtnSection">
        <a href="/sports" className="btn btn-primary PlayBtn">
          Play Now
        </a>
      </div>
    </div>
  );
};

export default index;
